import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../layout/Layout";
import Hero from "../pages/Hero";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" index element={<Hero />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
