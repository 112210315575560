import React from "react";
import {
  EmailLinerLink,
  TelegramLinerIcon,
  XlinerIcon,
  YoutubeLinerIcon,
} from "../constant/Icons";

const Footer = () => {
  const AllLink = [
    {
      id: 1,
      Link: "",
      icon: <EmailLinerLink />,
    },
    {
      id: 2,
      Link: "https://www.youtube.com/@MegsKombat",
      icon: <YoutubeLinerIcon />,
    },
    {
      id: 3,
      Link: "https://t.me/megskombatcommunity",
      icon: <TelegramLinerIcon />,
    },
    {
      id: 4,
      Link: "https://x.com/i/flow/login?redirect_after_login=%2Fmegskombat",
      icon: <XlinerIcon />,
    },
  ];

  return (
    <div className="w-full ">
      <div className="container mx-auto fixed bottom-3 lg:bottom-10 right-0 left-0">
        <div className="flex flex-col gap-3 lg:flex-row justify-between items-center">
          <div className="flex">
            <h1 className="text-xl text-white font-aileronItalic">
              All rights reserved
            </h1>
          </div>
          <div>
            <div className=" justify-center items-center gap-5 flex">
              {AllLink.map((item) => (
                <a
                  key={item.id}
                  href={item.Link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
