import React from "react";
import { Logo, TelegramIcon, YoutubeIcon } from "../constant/Icons";

const Navbar = () => {
  return (
    <div className="w-full ">
      <div className="lg:mx-auto mx-4 lg:container fixed top-0 left-0 right-0">
        <div className="flex items-center justify-between pt-3">
          <div className="flex items-center">
            <Logo width={85} height={65} />
            <h1 className="hidden lg:flex text-2xl text-white font-obliqueBold">
              MEGS COMBAT
            </h1>
          </div>
          <div className="relative rounded-full p-[3px] bg-gradient-to-r from-purple-500 via-pink-500 to-blue-500">
            <div className="flex items-center space-x-4 rounded-full bg-[#1B1A55] backdrop-blur-md  py-2 px-4">
              {/* YouTube Icon */}
              <a
                target="_blank"
                href="https://www.youtube.com/@MegsKombat"
                className="bg-gradient-to-b from-[#0094FF]/10 to-[#00B2FF]/20 flex items-center justify-center rounded-full w-10 h-10 lg:w-14 lg:h-14 border border-[#00B2FF] z-50"
              >
                <div className="hidden lg:flex">
                  <YoutubeIcon fill={"#fff"} width={33} height={23} />
                </div>
                <div className="flex lg:hidden">
                  <YoutubeIcon fill={"#fff"} width={20} height={25} />
                </div>
              </a>
              {/* Telegram Icon */}
              <a
                target="_blank"
                href="https://t.me/megskombatcommunity"
                className="bg-gradient-to-b from-[#0094FF]/10 to-[#00B2FF]/20 flex items-center justify-center rounded-full w-10 h-10 lg:w-14 lg:h-14  border border-[#00B2FF]"
              >
                <div className="hidden lg:flex">
                  <TelegramIcon fill={"#fff"} width={33} height={26} />
                </div>
                <div className="flex lg:hidden">
                  <TelegramIcon fill={"#fff"} width={20} height={26} />
                </div>
              </a>
              {/* Join Us Text */}
              <span className="text-white font-bold">Join us</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
