import React from "react";
import Image from "../assets/images/Rectangle 40.png";
import Image2 from "../assets/images/Rectangle 41.png";
import HeroShark from "../assets/images/Shark 2 balls 2.png";

const Hero = () => {
  return (
    <div className="w-full ">
      <div className="container lg:mx-auto relative">
        <img src={Image} className="fixed bottom-0 left-0 hidden lg:flex " />
        <img src={Image2} className="fixed bottom-0 left-0 flex lg:hidden " />
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="flex lg:hidden w-full">
            <Shark />
          </div>
          <div className="flex flex-col items-center text-center lg:text-start lg:items-start gap-4 lg:gap-10 w-full text-white  px-5">
            <h1 className="text-2xl iphone-11:text-4xl lg:text-6xl ">
              Enjoy More, Earn More!
            </h1>
            <p className="iphone-11:text-xl lg:text-4xl ">
              Unlock your potential, earn more, and enjoy with ease, the future
              is yours!
            </p>
          </div>
          <div className="hidden lg:flex w-full">
            <Shark />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

export function Shark() {
  return (
    <div className="flex justify-center items-center  w-full relative">
      {/* Add the shadow as a background behind the shark */}
      <div
        className="absolute text-center z-10  w-[370px] h-[500px] lg:w-[600px] lg:h-[800px]"
        style={{
          background:
            "radial-gradient(circle, rgba(97, 66, 154, 0.6), transparent 80%)",

          filter: "blur(40px)",
        }}
      ></div>
      <img
        src={HeroShark}
        alt="shark"
        className="h-[400px]  iphone-11:w-[349px] iphone-11:h-[514px] lg:w-[579px] lg:h-[800px]"
        style={{ zIndex: 10 }}
      />
    </div>
  );
}
